import React from "react";
import AboutUsImage from "../img/AboutUs.jpg";
import Paul from "../img/Paul.jpg";
import "./AboutUs.css";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

function AboutUs() {
	return (
		<div className="d-flex flex-column min-vh-100">
			<img
				src={AboutUsImage}
				width="100%"
				height="100%"
				alt="Michoud Pharmacy logo"
			/>
			<div className="container">
				<div className="row">
					<div className="col-sm-8">
						<br />
						<h3>About Michoud Pharmacy</h3>
						<br />
						<p>
							Michoud Pharmacy is one of New Orleans newest pharmacies located
							in the New Orleans East area. Our pharmacy specializes in serving
							our community with effective and efficient services.
						</p>
					</div>
					<div className="col-sm-4">
						<br />
						<h3 className="samedeliverycolor">
							<b>FREE Same Day Delivery</b>
						</h3>
						<br />
						<h3 className="samedeliverytext">
							<b>
								“Our approach is always hands-on, and we pride ourselves on
								providing the highest level of care and service for our
								customers.”
							</b>
						</h3>
						<br />
						<Link to="/transfer-my-prescription">
							<Button variant="primary" size="lg">
								GET FREE SAME DAY DELIVERY
							</Button>
						</Link>
						<br />
						<br />
					</div>
				</div>
				<div className="row">
					<br />
					<br />
					<h3>Meet the Pharmacist</h3>
					<div className="col-5">
						<img
							src={Paul}
							width="450"
							height="250"
							alt="Michoud Pharmacy logo"
						/>
					</div>
					<div className="col-10">
						<br />
						<p>
							My name is Paul Vo, Owner and Pharmacist in charge at Michoud
							Pharmacy. Born and raised in the Versailles Community located in
							Eastern New Orleans. I graduated from Xavier College of Pharmacy
							in 2014. My goal is to help bridge the gap in access to healthcare
							services within my community.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AboutUs;
