import React from "react";
import emailjs from "@emailjs/browser";
import "./TransferPrescriptionForm.css";

function TransferPrescriptionForm() {
	function sendEmail(e) {
		e.preventDefault();

		emailjs
			.sendForm(
				"service_a44v1m9",
				"template_0mea64r",
				e.target,
				"user_vcgLGRlsHa010lFOt10cy"
			)
			.then(
				(result) => {
					console.log(result.text);
				},
				(error) => {
					console.log(error.text);
				}
			);
		e.target.reset();
	}
	return (
		<div className="contact">
			<div className="container">
				<form className="contact-form" onSubmit={sendEmail}>
					<div className="col-sm form-group mx-auto">
						<h2 name="label-name">Patient Information:</h2>
						<input
							type="text"
							className="form-control form-bg-color"
							placeholder="Full Name"
							name="Fullname"
							required
						/>
					</div>
					<div className="col-sm form-group pt-3 mx-auto">
						<input
							type="text"
							className="form-control form-bg-color"
							placeholder="Full Address"
							name="FullAddress"
							required
						/>
					</div>
					<div className="col-sm form-group pt-3 mx-auto">
						<h4 name="label-name">Gender</h4>
						<select required="true" name="Gender">
							<option selected value=""></option>
							<option value="Male">Male</option>
							<option value="Female">Female</option>
						</select>
					</div>
					<div className="col-sm form-group pt-3 mx-auto">
						<h4 name="label-name">Date of Birth</h4>
						<input
							type="date"
							className="form-control form-bg-color"
							placeholder="Date of Birth"
							pattern="/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/"
							name="DateOfBirth"
							required
						/>
					</div>
					<div className="col-sm form-group pt-3 mx-auto">
						<h4 name="label-name">Phone Number</h4>
						<input
							type="text"
							className="form-control form-bg-color"
							placeholder="Format: 123-456-7890"
							pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
							name="phone"
							required
						/>
					</div>
					<div className="col-sm form-group pt-3 mx-auto">
						<input
							type="email"
							className="form-control form-bg-color"
							placeholder="Email Address"
							name="email"
							required
						/>
					</div>
					<div className="col-sm form-group pt-3 mx-auto">
						<input
							type="text"
							className="form-control form-bg-color"
							placeholder="Drug Name"
							name="DrugName"
						/>
					</div>
					<div className="col-sm form-group pt-3 mx-auto">
						<input
							type="text"
							className="form-control form-bg-color"
							placeholder="RX Prescription Number (Optional)"
							name="RxPrescriptionNumber"
						/>
					</div>
					<br />
					<div className="col-sm form-group mx-auto ">
						<h2 name="label-name">Transfer RX Information:</h2>
						<input
							type="text"
							className="form-control form-bg-color"
							placeholder="Current Pharmacy"
							name="CurrentPharmacy"
						/>
					</div>
					<div className="col-sm form-group pt-3 mx-auto">
						<h4 name="label-name">Pharmacy Phone Number</h4>
						<input
							type="text"
							className="form-control form-bg-color"
							placeholder="Format: 123-456-7890"
							pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
							name="PharmacyPhoneNumber"
						/>
					</div>
					<div className="col-sm form-group pt-3 mx-auto">
						<h4 name="label-name">
							Any addition medication you would like to transfer:
						</h4>
						<textarea
							cols="30"
							rows="8"
							className="form-control form-bg-color"
							placeholder="Add the current pharmacy, pharmacy phone number, drug name, and rx prescription number for each medication"
							name="AdditionalMessage"
						/>
					</div>
					<div className="col-sm form-group pt-3 mx-auto">
						<h4 name="label-name">Fill this prescription now?</h4>
						<select required="true" name="FillPrescription">
							<option selected value=""></option>
							<option value="Yes">Yes, fill now</option>
							<option value="No">No, save for later</option>
						</select>
					</div>
					<div className="col-sm form-group pt-3 mx-auto">
						<h4 name="label-name">
							How would you like to receive your prescription?
						</h4>
						<select required="true" name="ReceivedPrescription">
							<option selected value=""></option>
							<option value="Pickup">Pickup</option>
							<option value="Delivery">Delivery</option>
						</select>
					</div>
					<br />
					<div className="col-sm form-group mx-auto">
						<h2 name="label-name">Insurance Information:</h2>
						<h5>
							Save time at the pharmacy and answer a few questions about your
							prescription insurance. You may leave your insurance information
							blank. Please bring your insurance card to the pharmacy in case we
							need to verify your information.
						</h5>
						<br />
						<input
							type="text"
							className="form-control form-bg-color"
							placeholder="Name of Insurance Provider"
							name="InsuranceName"
						/>
					</div>
					<div className="col-sm form-group pt-3 mx-auto">
						<input
							type="text"
							className="form-control form-bg-color"
							placeholder="Insurance Provider Phone Number"
							name="InsuranceProviderPhoneNumber"
						/>
					</div>
					<div className="col-sm form-group pt-3 mx-auto">
						<input
							type="text"
							className="form-control form-bg-color"
							placeholder="Member Number"
							name="MemberNumber"
						/>
					</div>
					<div className="col-sm form-group pt-3 mx-auto">
						<input
							type="text"
							className="form-control form-bg-color"
							placeholder="Group Number (if available)"
							name="GroupNumber"
						/>
					</div>
					<div className="col-sm form-group pt-3 mx-auto">
						<input
							type="text"
							className="form-control form-bg-color"
							placeholder="BIN (if available)"
							name="BIN"
						/>
					</div>
					<div className="col-sm form-group pt-3 mx-auto">
						<input
							type="text"
							className="form-control form-bg-color"
							placeholder="PCN (if available)"
							name="PCN"
						/>
					</div>
					<div className="col-sm pt-3 mx-auto text-left">
						<input type="submit" className="btn btn-info block" value="Send" />
					</div>
				</form>
			</div>
		</div>
	);
}

export default TransferPrescriptionForm;
