import React from "react";
import GoogleMaps from "../components/googleMaps/GoogleMaps";
import {
	AiFillFacebook,
	AiFillInstagram,
	AiFillTwitterSquare,
} from "react-icons/ai";
import { Row } from "react-bootstrap";
import ContactPhoto1 from "../img/ContactPhoto1.JPG";
import ContactPhoto2 from "../img/ContactPhoto2.JPG";

function Contact() {
	return (
		<div className="d-flex flex-column min-vh-100">
			<GoogleMaps />
			<div className="container">
				<br />
				<Row xs={1} md={2}>
					<div className="col">
						<h3>Get Social</h3>
						<br />
						<a href="http://facebook.com/michoudpharmacy">
							<AiFillFacebook size={40} />
						</a>
						<a href="https://www.instagram.com/Michoudpharmacy">
							<AiFillInstagram size={40} />
						</a>
						<a href="https://twitter.com/MichoudPharmacy">
							<AiFillTwitterSquare size={40} />
						</a>
						<br />
						<br />
						<br />
						<div className="col">
							<h3>Pharmacy Hours</h3>
							<br />
							<div className="col">
								<h4>
									<b>Mon – Fri:</b> 9am to 5pm
								</h4>
								<h4>
									<b>Sat:</b> 9am to 2pm
								</h4>
								<h4>
									<b>Sun:</b> Closed
								</h4>
							</div>
						</div>
						<br />
						<div className="col">
							<h3>Contact Info</h3>
							<br />
							<div className="col-8">
								<h4>4646 Michoud Blvd. Ste D5 New Orleans, LA 70129</h4>
								<br />
								<h4>Phone: 504-435-1422</h4>
								<br />
								<h4>Fax: 504-435-1558</h4>
							</div>
						</div>
						<br />
					</div>
					<div className="container">
						<div className="col">
							<img
								src={ContactPhoto1}
								width="90%"
								height="70%"
								alt="Contact1"
								name="Contact1"
							/>
							<br />
							<br />
							<img
								src={ContactPhoto2}
								width="90%"
								height="70%"
								alt="Contact2"
								name="Contact2"
							/>
						</div>
					</div>
				</Row>
			</div>
		</div>
	);
}

export default Contact;
