import React from "react";
import GoogleMaps from "../components/googleMaps/GoogleMaps";
import CustomizedTimeline from "../components/timeline/CustomizedTimeline";
import HomeVideo from "../videos/HomeVideo";
import MichoudPharmacyStore from "../img/MichoudPharmacyStore.PNG";

function HomePage() {
	return (
		<div>
			<div className="container">
				<img
					src={MichoudPharmacyStore}
					width="100%"
					height="90%"
					alt="Michoud Pharmacy logo"
					name="MichoudStore"
				/>
			</div>

			<br />
			<h1 className="text-center">WELCOME TO MICHOUD PHARMACY</h1>
			<br />
			<div className="container">
				<div className="col-12">
					<HomeVideo />
				</div>
				<br />
				<div className="col-12">
					<h3 className="text-left">
						Imagine the day when you never have to wait in line with other sick
						people to get your prescription. The day has come, Michoud Pharmacy
						is New Orleans Pharmacy on Wheels.
					</h3>
				</div>
				<br />
				<div className="col-12">
					<h3 className="text-left">
						No more long lines! No more driving while you’re sick. Get your
						prescriptions delivered by a local Michoud Pharmacy rep the same day
						without leaving your home.
					</h3>
				</div>
				<br />
				<div className="col-12">
					<h3 className="text-left">
						It’s a simple 4 step process to enroll. Once you’ve completed it,
						one of our drivers will come to you!
					</h3>
				</div>
			</div>
			<hr></hr>
			<div className="container">
				<h1 className="text-center">HOW IT WORKS</h1>
				<CustomizedTimeline />
			</div>
			<br />
			<GoogleMaps />
		</div>
	);
}
export default HomePage;
