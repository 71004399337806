import React from "react";

function FAQ() {
	return (
		<div>
			<br />
			<div className="container">
				<div className="container text-center">
					<h3>Frequently Asked Questions – FAQs</h3>
				</div>
				<br />
				<div className="container">
					<h4>How Can I Sign Up?</h4>
					<p>
						Give us a call so we can gather some health information, request
						your doctor to send your prescriptions to Michoud Pharmacy, confirm
						a delivery address and date/time, provide us with a credit card
						information for co-pays.
					</p>
					<hr></hr>
					<h4>Can I sign up for any of my family member as a caregiver?</h4>
					<p>Yes, we just need an ID on file to confirm delivery.</p>
					<hr></hr>
					<h4>Do we deliver to a business?</h4>
					<p>Yes, we can deliver to a business if requested.</p>
					<hr></hr>
					<h4>What is the cut off time for delivery?</h4>
					<p>4pm weekdays and 12pm Saturday’s.</p>
					<hr></hr>
					<h4>What does it cost for delivery?</h4>
					<p>There is no charge for delivery.</p>
					<hr></hr>
					<h4>
						If a medication is not in stock, when will I be able to get it?
					</h4>
					<p>
						If your medication is not in stock, we can have it ordered and
						delivered for the next day. (Excluding weekends).
					</p>
					<hr></hr>
					<h4>Do you deliver on the weekend?</h4>
					<p>Yes, Saturday only. We are closed Sundays.</p>
					<hr></hr>
					<h4>What immunizations do you administer? </h4>
					<p>
						Pfizer, Moderna, J&amp;J vaccines, flu shots, Pneumonia, Shingles,
						Hep A/B, Tetanus, Whooping Cough, and more. Contact us for more
						details.
					</p>
					<hr></hr>
					<h4>
						After my rapid COVID test, how long does it take to get results?
					</h4>
					<p>
						After testing, results will take 15 minutes and will be sent through
						via text and email.
					</p>
					<hr></hr>
					<h4>How old does my child have to be to get a flu shot?</h4>
					<p>We recommend the child to be at least 7 years old.</p>
					<hr></hr>
					<h4>
						Is there a charge for medication therapy management to identify drug
						interactions, side effects?
					</h4>
					<p>
						No, we offer MTM services free of charge. Just set a time and date
						to meet with the pharmacist.
					</p>
					<hr></hr>
					<h4>
						How do I transfer my prescriptions from another pharmacy to yours?
					</h4>
					<p>
						We would need your name, date of birth and the current pharmacy
						phone number. We’ll call them and transfer your prescriptions to us.
					</p>
				</div>
			</div>
		</div>
	);
}

export default FAQ;
