import React from "react";
import RefillMyPrescriptionForm from "../components/forms/refillMyPrescriptionForm/RefillMyPrescriptionForm";
import AboutUsImage from "../img/AboutUs.jpg";

function RefillMyPrescription() {
	return (
		<div className="d-flex flex-column min-vh-100">
			<img
				src={AboutUsImage}
				width="100%"
				height="100%"
				alt="Michoud Pharmacy logo"
			/>
			<br />
			<br />
			<div className="container">
				<div className="container">
					<h3>Refill My Prescription</h3>
				</div>
				<br />
				<div className="container">
					<h5>
						Please input your RX Number so we can refill your prescription(s)
						and we will have it ready as quickly as possible.
					</h5>
				</div>
				<RefillMyPrescriptionForm />
			</div>
		</div>
	);
}

export default RefillMyPrescription;
