import React, { Component } from "react";

class GoogleMaps extends Component {
	render() {
		return (
			<div className="container">
				<iframe
					title="michoudpharm"
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d882438.2751859174!2d-90.98597813295953!3d30.236377476108363!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x889e0388a0847681%3A0x95d31f9c5f3ddccb!2sMichoud%20Pharmacy!5e0!3m2!1sen!2sus!4v1641679852141!5m2!1sen!2sus"
					// width="800"
					// height="450"
					width="100%"
					height="100%"
					style={{ border: "0" }}
					loading="lazy"
				></iframe>
			</div>
		);
	}
}
export default GoogleMaps;
