import React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import Looks4Icon from "@mui/icons-material/Looks4";
import Typography from "@mui/material/Typography";

function CustomizedTimeline() {
	return (
		<div className="container">
			<Timeline position="alternate">
				<TimelineItem>
					<TimelineOppositeContent
						sx={{ m: "auto 0" }}
						align="right"
						variant="body2"
						color="text.secondary"
					></TimelineOppositeContent>
					<TimelineSeparator>
						<TimelineConnector />
						<TimelineDot color="primary">
							<LooksOneIcon />
						</TimelineDot>
						<TimelineConnector />
					</TimelineSeparator>
					<TimelineContent sx={{ py: "12px", px: 2 }}>
						<Typography variant="h4" component="span">
							Contact Us
						</Typography>
						<Typography variant="h6">
							Call (504) 435-1422 or fill out our enrollment form.
						</Typography>
					</TimelineContent>
				</TimelineItem>
				<TimelineItem>
					<TimelineOppositeContent
						sx={{ m: "auto 0" }}
						variant="body2"
						color="text.secondary"
					></TimelineOppositeContent>
					<TimelineSeparator>
						<TimelineConnector />
						<TimelineDot color="primary">
							<LooksTwoIcon />
						</TimelineDot>
						<TimelineConnector />
					</TimelineSeparator>
					<TimelineContent sx={{ py: "12px", px: 2 }}>
						<Typography variant="h4" component="span">
							Requesting Your Prescription
						</Typography>
						<Typography variant="h6">
							Either have your doctor contact us, transfer your existing
							prescription from another pharmacy, or we can contact your doctor
							to request a new prescription.
						</Typography>
					</TimelineContent>
				</TimelineItem>
				<TimelineItem>
					<TimelineSeparator>
						<TimelineConnector />
						<TimelineDot color="primary">
							<Looks3Icon />
						</TimelineDot>
						<TimelineConnector />
					</TimelineSeparator>
					<TimelineContent sx={{ py: "12px", px: 2 }}>
						<Typography variant="h4" component="span">
							Confirm Delivery Date/Time
						</Typography>
						<Typography variant="h6">
							Once we receive your prescription, we coordinate the time and
							place to drop off your medications.
						</Typography>
					</TimelineContent>
				</TimelineItem>
				<TimelineItem>
					<TimelineSeparator>
						<TimelineConnector />
						<TimelineDot color="primary">
							<Looks4Icon />
						</TimelineDot>
						<TimelineConnector />
					</TimelineSeparator>
					<TimelineContent sx={{ py: "12px", px: 2 }}>
						<Typography variant="h4" component="span">
							Get Your Meds
						</Typography>
						<Typography variant="h6">
							Upon arrival, all the patient or caregiver has to do is sign for
							the medications. It's that easy!
						</Typography>
					</TimelineContent>
				</TimelineItem>
			</Timeline>
		</div>
	);
}

export default CustomizedTimeline;
