import React from "react";
import emailjs from "@emailjs/browser";

function RefillMyPrescriptionForm() {
	function sendEmail(e) {
		e.preventDefault();

		emailjs
			.sendForm(
				"service_a44v1m9",
				"template_hbli6ak",
				e.target,
				"user_vcgLGRlsHa010lFOt10cy"
			)
			.then(
				(result) => {
					console.log(result.text);
				},
				(error) => {
					console.log(error.text);
				}
			);
		e.target.reset();
	}
	return (
		<div className="refillPrescription">
			<div className="container">
				<form className="refill-prescription-form" onSubmit={sendEmail}>
					<div className="col-sm form-group pt-3 mx-auto ">
						<input
							type="text"
							className="form-control form-bg-color"
							placeholder="RX Number 1"
							name="RxNumber1"
							required
						/>
					</div>
					<div className="col-sm form-group pt-3 mx-auto ">
						<input
							type="text"
							className="form-control form-bg-color"
							placeholder="RX Number 2"
							name="RxNumber2"
						/>
					</div>
					<div className="col-sm form-group pt-3 mx-auto ">
						<input
							type="text"
							className="form-control form-bg-color"
							placeholder="RX Number 3"
							name="RxNumber3"
						/>
					</div>
					<div className="col-sm form-group pt-3 mx-auto">
						<h4 name="label-name">Additional RX number(s):</h4>
						<textarea
							cols="30"
							rows="8"
							className="form-control form-bg-color"
							name="AdditionalRxNumbers"
						/>
					</div>
					<div className="col-sm form-group pt-3 mx-auto">
						<h4 name="label-name">
							How would you like to receive your prescription?
						</h4>
						<select required="true" name="FillPrescription">
							<option selected value=""></option>
							<option value="Pickup">Pickup</option>
							<option value="Delivery">Delivery</option>
						</select>
					</div>
					<div className="col-sm form-group pt-3 mx-auto">
						<input
							type="email"
							className="form-control form-bg-color"
							placeholder="Email Address (Optional)"
							name="email"
						/>
					</div>
					<div className="col-sm pt-3 mx-auto text-left">
						<input type="submit" className="btn btn-info block" value="Send" />
					</div>
				</form>
			</div>
		</div>
	);
}

export default RefillMyPrescriptionForm;
