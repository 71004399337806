import React from "react";
import video1 from "./michoudpharmacy_intro.mp4";

function HomeVideo() {
	return (
		<div>
			<video
				src={video1}
				width="100%"
				height="100%"
				controls
				muted
				autoPlay={"autoplay"}
				preLoad="auto"
				loop
			/>
		</div>
	);
}
export default HomeVideo;
