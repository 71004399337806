import React from "react";
import AboutUsImage from "../img/AboutUs.jpg";

function OurServices() {
	return (
		<div className="d-flex flex-column min-vh-100">
			<img
				src={AboutUsImage}
				width="100%"
				height="100%"
				alt="Michoud Pharmacy logo"
			/>
			<br />
			<br />
			<div className="container">
				<div className="row">
					<div className="col-sm-8">
						<h3>Our Services</h3>
						<br />
						<h5>Our main services cover the following areas and needs:</h5>
					</div>
				</div>
				<br />
				<div className="row">
					<div className="col-5">
						<div className="col-sm-8">
							<ul>
								<li>Immunizations</li>
								<li>Medication Therapy Management</li>
								<li>Automatic Monthly Refill</li>
								<li>Rapid COVID Testing</li>
							</ul>
						</div>
					</div>
					<div className="col-5">
						<div className="col-sm-6">
							<ul>
								<li>FREE Same Day Delivery</li>
								<li>E-Script Available</li>
								<li>Long Term Care</li>
								<li>340B</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default OurServices;
