import Footers from "./components/footer/Footer";
import HomePage from "./pages/HomePage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AboutUs from "./pages/AboutUs";
import TransferMyPrescription from "./pages/TransferMyPrescription";
import RefillMyPrescription from "./pages/RefillMyPrescription";
import OurServices from "./pages/OurServices";
import FAQ from "./pages/FAQ";
import Contact from "./pages/Contact";
import Header from "./components/header/Header";

function App() {
	return (
		<div className="App">
			<Header />
			<Router>
				<Routes>
					<Route path="/" element={<HomePage />} />
					<Route path="/about-us" element={<AboutUs />} />
					<Route
						path="/transfer-my-prescription"
						element={<TransferMyPrescription />}
					/>
					<Route
						path="/refill-my-prescription"
						element={<RefillMyPrescription />}
					/>
					<Route path="/our-services" element={<OurServices />} />
					<Route path="/faq" element={<FAQ />} />
					<Route path="/contact" element={<Contact />} />
				</Routes>
			</Router>

			<Footers />
		</div>
	);
}

export default App;
