import React from "react";
import { MDBContainer } from "mdbreact";
import "./Footer.css";

function Footers() {
	return (
		<footer className="font-small pt-4 mt-auto">
			<div
				className="footer-copyright text-center py-3"
				style={{ backgroundColor: "#004daa" }}
			>
				<MDBContainer fluid>
					<p className="footerText">
						Copyright &copy; {new Date().getFullYear()} Michoud Pharmacy. All
						Rights Reserved.
					</p>
				</MDBContainer>
			</div>
		</footer>
	);
}
export default Footers;
