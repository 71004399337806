import React from "react";
import MichoudLogo from "../../img/michoudlogo.png";
import { BsFillTelephoneFill } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import "./Header.css";

function Header() {
	return (
		<nav class="navbar navbar-expand-lg navbar-light bg-light">
			<div class="container-fluid">
				<a class="navbar-brand" href="/">
					<img
						src={MichoudLogo}
						width="100"
						height="30"
						alt="Michoud Pharmacy logo"
					/>
				</a>
				<button
					class="navbar-toggler"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target="#navbarNavAltMarkup"
					aria-controls="navbarNavAltMarkup"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<GiHamburgerMenu size={20} />
				</button>
				<div class="collapse navbar-collapse" id="navbarNavAltMarkup">
					<div class="navbar-nav mx-auto">
						<a class="nav-link" aria-current="page" href="/about-us">
							About Us
						</a>
						<a class="nav-link" href="/transfer-my-prescription">
							Transfer My Prescription
						</a>
						<a class="nav-link" href="/refill-my-prescription">
							Refill My Prescription
						</a>
						<a class="nav-link" href="/our-services">
							Our Services
						</a>
						<a class="nav-link" href="/faq">
							FAQ
						</a>
						<a class="nav-link" href="/contact">
							Contact
						</a>
						<a class="navbar-brand" href="/transfer-my-prescription">
							<button className="btn btn btn-primary btn-sm">
								FREE Get Same Day Delivery
							</button>
						</a>
						<a class="navbar-brand" href="tel:1+504-435-1422">
							<button className="btn btn  btn-sm">
								<BsFillTelephoneFill size={20} />
								504-435-1422
							</button>
						</a>
					</div>
				</div>
			</div>
		</nav>
	);
}

export default Header;
