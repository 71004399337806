import React from "react";
import AboutUsImage from "../img/AboutUs.jpg";
import TransferPrescriptionForm from "../components/forms/transferPrescriptionForm/TransferPrescriptionForm";

function TransferMyPrescription() {
	return (
		<div>
			<img
				src={AboutUsImage}
				width="100%"
				height="100%"
				alt="Michoud Pharmacy logo"
			/>
			<br />
			<br />
			<div className="container">
				<div className="container">
					<h3>Transfer My Prescription to Michoud Pharmacy</h3>
				</div>
				<br />
				<div className="container">
					<h5>
						We'll use the information you provide here to contact your pharmacy
						and transfer your prescription(s) to Michoud Pharmacy.
					</h5>
				</div>
				<br />
				<TransferPrescriptionForm />
			</div>
		</div>
	);
}

export default TransferMyPrescription;
